import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { useEffect } from "react";
import useCustomerContext from "./hooks/use-customer-context";
import Header from './components/Header';
import TradingTerms from './components/TradingTerms';
import Footer from './components/Footer';

const App = () => {

    const { fetchData, initLoaded } = useCustomerContext();

    useEffect(() => {
        fetchData();
    },[fetchData]);

    return(
        (initLoaded &&
        <div className="app">
            <Header />
            <TradingTerms />
            <Footer />
        </div>
        )
    );
}
export default App;