import { createContext, useState, useCallback } from "react";
import axios from 'axios';

const env = {
    apiURL: 'https://webapi.montmarte.net/'
}

const CustomerContext = createContext();

const Provider = ({children}) => {

    const [customer, setCustomer] = useState({});
    const [terms, setTerms] = useState({__html: ""});
    const [loading, setLoading] = useState(false);
    const [initLoaded, setInitLoaded] = useState(false);

    const hash = window.location.pathname.replace(/[^a-zA-Z0-9]/g, '');

    const fetchData = useCallback(async () => {

        const getMarkUp = async () => {
            try {
                let response;
                response = await axios.get(`${env.apiURL}trading_terms`);
                const htmlString = response.data;
                setTerms({__html: htmlString});
            } catch (err) {
                console.log(err);
            }
        }

        getMarkUp();

        if(typeof hash !== 'undefined' && hash !== '' && hash.indexOf('index')===-1) {
            try {
                const resp = await axios.get(`${env.apiURL}customer_detail?ch=${hash}`);
                setCustomer({...resp.data.data});
            } catch (err) {
                //console.log(err);
            }
        }

        setInitLoaded(true);

    }, [hash]);

    const handleAgree = async () => {
        try {
            setLoading(true);
            const update = await axios.put(`${env.apiURL}customer_terms`,{
                data: {ch: hash}
            });
            if(update.data.status === 'success') {
                setCustomer({...update.data.data});
            }
            setLoading(false);
        }catch(err){
            console.log(err);
        }
    }

    return (
        <CustomerContext.Provider value={{loading, initLoaded, customer, terms, fetchData, handleAgree}}>
            {children}
        </CustomerContext.Provider>
    );

}

export { Provider };
export default CustomerContext;