import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from './context/customer.js';
import App from './App.js';

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

root.render(
    <Provider>
    <App />
    </Provider>
);