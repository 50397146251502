const Header = () => {
    return (
        <>
        <div className="container-fluid header__container">
        <div className="row">
            <div className="top-header"></div>
        </div>
        </div>
        <div className="header position-sticky top-0 bg-white">
        <div className="container">
        <div className="row">
            <div className="col">
                <div className="header__inner">
                    <div className="logo">
                        <img alt="MontMarte" src="//www.montmarte.com.au/cdn/shop/files/MM-Horizontal-Logo-black_2160x.png?v=1661897828" />
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        </>
    );
}
export default Header;