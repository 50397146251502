import { useState, useEffect } from "react";
import useCustomerContext from "../hooks/use-customer-context";
function Footer() {

    const { customer, handleAgree, loading } = useCustomerContext();
    const [content, setContent] = useState('');

    useEffect(() => { 
        
        let htm = '';

        if(typeof customer.CustomerTerms !== 'undefined') {

            if(customer.CustomerTerms !== 'Yes') {
                htm = (
                    <div className="container">
                        As a representative of <strong>{customer.BusinessName}</strong> ABN:<strong>{customer.ABN}</strong>, I agree to Mont Marte Trading Terms above
                        <button className="btn btn-lg btn-success ms-3" disabled={loading} onClick={handleAgree}>{loading ? <>Loading..</> : <>Agree</>}</button>
                    </div>);
            }else{
                htm = (
                    <div className="container">
                        Thank you <strong>{customer.BusinessName}</strong> Trading Terms have been accepted.
                    </div>
                );
            }

        }

        setContent(htm);
        
    }, [customer, handleAgree, loading]);

    return (
        (content &&
        <div className="position-sticky bottom-0 p-3 border-top border-1 footer">
            {content}
        </div>
        )
    );
}
export default Footer;