import { useState, useEffect } from 'react';
import useCustomerContext from "../hooks/use-customer-context";
function TradingTerms() {

    const { customer, terms } = useCustomerContext();
    const [alert, setAlert] = useState('');

    useEffect(() => {
        if (typeof customer.BusinessName !== 'undefined') {

            let content = (<>Hi <strong>{customer.BusinessName}</strong>, we've recently updated our Trading Terms. Please review below & accept at the bottom of the page.</>);
            let alertClass = 'alert-mm-yellow';

            if (customer.CustomerTerms === "Yes") {
                content = (<><i className="tick-icon"></i> Thank you <strong>{customer.BusinessName}</strong>, below Trading Terms have been accepted.</>);
                alertClass = "alert-mm-green";
            }

            const renderAlert = (
                <div className="container pt-4 pb-1 mb-1">
                    <div className={"alert rounded-0 border-0 p-4 " + alertClass}>
                        <h5 className="m-0">{content}</h5>
                    </div>
                </div>
            );

            setAlert(renderAlert);

        }
    }, [customer]);

    return (
        <>
            {alert}
            <div className="container pt-1 pb-3 terms__container" dangerouslySetInnerHTML={terms}></div>
        </>
    );
}
export default TradingTerms;